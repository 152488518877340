import * as React from 'react';
import { ChevronStartIcon } from '@fluentui/react-icons-northstar';
import { Button, Flex, Text } from '@fluentui/react-northstar';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const NavigationButton = ({ goBack = false }: { goBack?: boolean }) => {
  const { t } = useTranslation();
  const navigate = useHistory();
  const navigateToOverview = () => {
    if (goBack) {
      navigate.goBack();
    } else {
      navigate.push({ pathname: '/resourceRequests' });
    }
  };

  return (
    <Flex>
      <Button text primary onClick={navigateToOverview} styles={{ paddingLeft: '-16px' }}>
        <Text size="large">
          <ChevronStartIcon styles={{ marginBottom: '2px' }} />
          {goBack
            ? t('resources-detail-view.go-back-link-text')
            : t('resources-detail-view.link-back-to-details-pane')}
        </Text>
      </Button>
    </Flex>
  );
};
