import { ResourceRequests } from '../../../data-access/hooks/queries/query-params';
import { useResourceRequestsOverview } from '../../../data-access/hooks/queries/use-resource-requests-overview';
import { convertMonthYear } from '../../../utils/dateConverter';

export interface UseProjectListDataHookParams {
  paginationOptions: {
    currentPage: number;
    pageSize: number;
  };
  open?: boolean;
  orderBy?: ResourceRequests['orderBy'];
}

export const useResourceRequestOverviewData = ({
  paginationOptions,
  open,
  orderBy,
}: UseProjectListDataHookParams) => {
  const result = useResourceRequestsOverview(
    {
      skip: (paginationOptions.currentPage - 1) * paginationOptions.pageSize,
      take: paginationOptions.pageSize,
      open,
      orderBy,
    },
    { keepPreviousData: true }
  );
  const data = result.data?.items.map((resourceRequest) => {
    return {
      ...resourceRequest,
      duration: `${convertMonthYear(resourceRequest.projectStart)} - ${convertMonthYear(
        resourceRequest.projectEnd
      )}`,
    };
  });

  return { data: data ?? [], totalItems: result.data?.totalCount ?? 0 };
};
