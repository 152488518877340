import { useMutation, useQueryClient } from 'react-query';
import { env } from '../../../utils/env';
import { ApiError, EmployeeService } from '../../open-api/commands';
import { EmployeeService as QueryEmployeeService } from '../../open-api/queries';
import { Result } from '../../open-api/commands/models/Result';
import * as CommandParams from './command-params';
import { QueryKeys } from '../../query-keys';
import { useTranslation } from 'react-i18next';
import { waitForEvent } from './wait-for-event';

const updateEmployeeTemporaryState = (input: CommandParams.UpdateEmployeeTemporaryStateInput) =>
  EmployeeService.editTemporaryState({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const addEmployeeProject = (input: CommandParams.AddEmployeeProjectInput) =>
  EmployeeService.addEmployeeProject({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const updateEmployeeProject = (input: CommandParams.ChangeEmployeeProjectInput) =>
  EmployeeService.changeEmployeeProject({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const updateEmployeeProjects = (input: CommandParams.UpdateEmployeeProjectInput) =>
  EmployeeService.updateEmployeeProjects({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const deleteEmployeeProject = (input: CommandParams.DeleteEmployeeProjectInput) =>
  EmployeeService.deleteEmployeeProject({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const ignoreEmployee = (input: CommandParams.IgnoreEmployeeInput) =>
  EmployeeService.ignoreEmployee({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const unignoreEmployee = (input: CommandParams.IgnoreEmployeeInput) =>
  EmployeeService.ignoreEmployee({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const getEventPosition = (id: string) =>
  QueryEmployeeService.getEventPosition({
    version: env('REACT_APP_API_VERSION'),
    id,
  });

type GuidResultWithId = Result & {
  id: string;
};

export const useUpdateEmployeeTemporaryState = (requestObject: string, type: string) => {
  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, CommandParams.UpdateEmployeeTemporaryStateInput>(
    async (input) => {
      const result = await updateEmployeeTemporaryState(input);

      return { ...result, id: input.id };
    },
    {
      onSuccess: async ({ id, storePosition }) => {
        waitForEvent({ id, storePosition }, getEventPosition).then(() => {
          queryClient.invalidateQueries([QueryKeys.EmployeeOverview, id]);
          queryClient.refetchQueries([QueryKeys.EmployeeDetail, id]);
        });
      },
      meta: {
        type: type,
        requestObject: requestObject,
      },
    }
  );
};

export const useAddEmployeeProject = (requestObject: string, type: string) => {
  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, CommandParams.AddEmployeeProjectInput>(
    async (input) => {
      const result = await addEmployeeProject(input);

      return { ...result, id: input.employeeId };
    },
    {
      onSuccess: async ({ id, storePosition }) => {
        waitForEvent({ id, storePosition }, getEventPosition).then(() => {
          queryClient.invalidateQueries(QueryKeys.EmployeeOverview);
          queryClient.refetchQueries(QueryKeys.EmployeeProjects);
          queryClient.refetchQueries(QueryKeys.EmployeeMonthlyWorkloads);
        });
      },
      meta: {
        type: type,
        requestObject: requestObject,
      },
    }
  );
};

export const useEditEmployeeProject = (requestObject: string, type: string) => {
  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, CommandParams.ChangeEmployeeProjectInput>(
    async (input) => {
      const result = await updateEmployeeProject(input);

      return { ...result, id: input.id };
    },
    {
      onSuccess: async ({ id, storePosition }) => {
        waitForEvent({ id, storePosition }, getEventPosition).then(() => {
          queryClient.invalidateQueries(QueryKeys.EmployeeOverview);
          queryClient.refetchQueries(QueryKeys.EmployeeProjects);
        });
      },
      meta: {
        type: type,
        requestObject: requestObject,
      },
    }
  );
};

export const useUpdateEmployeeProjects = (requestObject: string, type: string) => {
  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, CommandParams.UpdateEmployeeProjectInput>(
    async (input) => {
      const result = await updateEmployeeProjects(input);

      return { ...result, id: input.employeeId };
    },
    {
      onSuccess: async ({ id, storePosition }) => {
        waitForEvent({ id, storePosition }, getEventPosition).then(() => {
          queryClient.invalidateQueries(QueryKeys.EmployeeOverview);
          queryClient.refetchQueries(QueryKeys.EmployeeProjects);
          queryClient.refetchQueries(QueryKeys.EmployeeMonthlyWorkloads);
        });
      },
      meta: {
        type: type,
        requestObject: requestObject,
      },
    }
  );
};

export const useDeleteEmployeeProject = (requestObject: string, type: string) => {
  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, CommandParams.DeleteEmployeeProjectInput>(
    async (input) => {
      const result = await deleteEmployeeProject(input);

      return { ...result, id: input.employeeId };
    },
    {
      onSuccess: async ({ id, storePosition }) => {
        waitForEvent({ id, storePosition }, getEventPosition).then(() => {
          queryClient.invalidateQueries(QueryKeys.EmployeeOverview);
          queryClient.refetchQueries(QueryKeys.EmployeeProjects);
        });
      },
      meta: {
        type: type,
        requestObject: requestObject,
      },
    }
  );
};

export const useIgnoreEmployee = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, CommandParams.IgnoreEmployeeInput>(
    async (input) => {
      const result = await ignoreEmployee(input);

      return { ...result, id: input.employeeId };
    },
    {
      onSuccess: async ({ id, storePosition }) => {
        waitForEvent({ id, storePosition }, getEventPosition).then(() => {
          queryClient.invalidateQueries(QueryKeys.EmployeeOverview);
          queryClient.refetchQueries(QueryKeys.EmployeeDetail);
        });
      },
      meta: {
        type: t('alert.update-types.edit'),
        requestObject: t('alert.request-objects.employee'),
      },
    }
  );
};

export const useUnignoreEmployee = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, CommandParams.IgnoreEmployeeInput>(
    async (input) => {
      const result = await unignoreEmployee(input);

      return { ...result, id: input.employeeId };
    },
    {
      onSuccess: async ({ id, storePosition }) => {
        waitForEvent({ id, storePosition }, getEventPosition).then(() => {
          queryClient.invalidateQueries(QueryKeys.EmployeeOverview);
          queryClient.refetchQueries(QueryKeys.EmployeeDetail);
        });
      },
      meta: {
        type: t('alert.update-types.edit'),
        requestObject: t('alert.request-objects.employee'),
      },
    }
  );
};
