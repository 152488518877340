import { atom } from 'recoil';
import { ResourceRequests } from '../../data-access/hooks/queries/query-params';

export const selectedResourceRequestAtom = atom<string | undefined>({
  key: 'selectedResourceRequestAtom',
  default: undefined,
});

export const selectedRequestFilterAtom = atom<{
  open: boolean;
  orderBy: ResourceRequests['orderBy'];
}>({
  key: 'selectedRequestFilter',
  default: {
    open: true,
    orderBy: 'SubmissionDate',
  },
});
